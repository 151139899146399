import '../styles/main.scss';

import React, {Component} from 'react';
import {connectToStore} from './Provider';
import {SwitchTransition, Transition} from 'react-transition-group';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import PasswordScreen from './PasswordScreen';
import Analytics from './Analytics';
import Cart from './Cart';
import Zendesk from './Zendesk';
import Takeover from './Takeover';
import Header from './Header';
import Footer from './Footer';
import BottomRibbon from './BottomRibbon';
import reduce from 'lodash/reduce';
import { products, productTemplate } from '../api/queries';

export const TRANSITION_DURATION = 400;

const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

class Layout extends Component {
	constructor({pageContext}) {
		super();

		this.state = {
			mounted: false,
		};
	}

	componentDidMount = () => {
		this.setState({mounted: true}); // eslint-disable-line
	};

	render() {
		const {
			children,
			pageContext = {},
			isScrollDisabled,
			hasEnteredSitePassword,
			location,
			customer,
		} = this.props;
		const isCanadaLandingPage=location.pathname?.startsWith("/can-kids-pain-fever-blueberry-oral-suspension");
		const {
			passwordProtection = {},
			header = {},
			footer = {},
			cart = {},
			disableFooter,
			layout,
			bundleBuilderOptions = [],
			cartOptions = [],
			zendesk = {},
			cookiesDisclaimer = {},
		} = pageContext;

		const {
			freeShippingMinimumCents,
			bulkDiscountMinimumItems,
			bulkDiscountPercentage,
			emptyState,
			medicineCabinetTitle,
			medicineCabinetDescription,
			medicineCabinetCta,
			relatedProductsTitle,
			customerDiscountBanner,
			customerDiscountTags = [],
		} = cart;

		const {
			searchPlaceholder,
			takeoverLinks = [],
			contactTitle,
			contactDescription = [],
		} = header;

		const {routeConfig = []} = zendesk;

		const tags = customer ? customer.tags || [] : [];

		const isCustomerDiscount = reduce(
			tags,
			(isCustomerDiscount, tag) => {
				if (customerDiscountTags.indexOf(tag) > -1) {
					return true;
				} else return isCustomerDiscount;
			},
			false,
		);

		const {mounted} = this.state;

		let landingPageData =	typeof window !== 'undefined'
		? localStorage.getItem('landingPage')
		: false;

		if(landingPageData) {
			landingPageData = JSON.parse(landingPageData)
		}

		// REMOVE THIS TO CONTINUE WITH LANDING PAGES
		if(typeof window !== 'undefined') {
			localStorage.removeItem('landingPage');
		}

		// if(typeof window !== 'undefined') {
		// 	// const queryString = window.location.search;
		// 	// if(!queryString) {
		// 	// 	localStorage.removeItem('landingPage');
		// 	// }
		// }

		// Render nothing if we're password protected
		if (passwordProtection.enablePassword && !mounted) {
			return <React.Fragment />;
		}

		const showPasswordProtection =
			passwordProtection.enablePassword && !hasEnteredSitePassword;
		const isAccount = layout === 'account';

		// Banner text overeride based on Landing page content
		// This content is pulled from localstate.
		const bannerOverride = landingPageData && landingPageData.customStatusMessage
		? landingPageData.customStatusMessage
		: false;

		const popupOverride = landingPageData && landingPageData.customModalContent
		? landingPageData.customModalContent
		: false;

		const notificationBar = (
			<div className="notification_bar">
				<h1>Free Product Added!</h1>
			</div>
		);

		const headerSection = (
			<Header
				location={location}
				bannerOverride={bannerOverride}
				popupOverride={popupOverride}
				{...header}
				discountBanner={
					isCustomerDiscount ? customerDiscountBanner : null
				}
			/>
		);

		const cartSection = (
			<Cart
				freeShippingMinimumCents={freeShippingMinimumCents}
				bulkDiscountMinimumItems={bulkDiscountMinimumItems}
				bulkDiscountPercentage={bulkDiscountPercentage}
				emptyState={emptyState}
				medicineCabinetTitle={medicineCabinetTitle}
				medicineCabinetDescription={medicineCabinetDescription}
				medicineCabinetCta={medicineCabinetCta}
				relatedProductsTitle={relatedProductsTitle}
				freeproductOptions={cartOptions}
			/>
		);

		const takeoverSection = (
			<Takeover
				searchPlaceholder={searchPlaceholder}
				takeoverLinks={takeoverLinks}
				contactTitle={contactTitle}
				contactDescription={contactDescription}
			/>
		);

		const bottomRibbonSection = (
			<BottomRibbon
				cookiesDisclaimer={cookiesDisclaimer}
				productOptions={bundleBuilderOptions}
				freeproductOptions={cartOptions}
				location={location}
			/>
		);

		const skip = !isCanadaLandingPage && (
			<a
				aria-label="Skip to main content"
				name="maincontent"
				className="fix top left z10 skip button--primary"
				href="#maincontent">
				Skip to main content
			</a>
		);

		const siteFragment = !showPasswordProtection && !isAccount && (
			<React.Fragment>
				{
					!isCanadaLandingPage && <>
						{headerSection}
						{/* {notificationBar} */}
						{cartSection}
						{takeoverSection}
						{bottomRibbonSection}
					</>
				}
				<SwitchTransition>
					<Transition
						key={location.pathname}
						mountOnEnter
						unmountOnExit
						appear
						timeout={TRANSITION_DURATION}>
						{status => (
								<div
									id="maincontent"
									style={{
										...TRANSITION_STYLES.default,
										...TRANSITION_STYLES[status],
									}}>
									{children}
									{!isCanadaLandingPage && !disableFooter && <Footer {...footer} />}
                                    <Zendesk
										zendeskId={process.env.GATSBY_ZENDESK_ID}
										location={location}
										routeConfig={routeConfig}
									/>
								</div>
						)}
					</Transition>
				</SwitchTransition>
			</React.Fragment>
		);

		// Remove page transitions on client side routes
		const accountFragment = !showPasswordProtection && isAccount && (
			<React.Fragment>
				{headerSection}
				{cartSection}
				{takeoverSection}
				{bottomRibbonSection}
				<div id="maincontent">
					{children}
					{!disableFooter && <Footer {...footer} />}
					<Zendesk
						zendeskId={process.env.GATSBY_ZENDESK_ID}
						location={location}
						routeConfig={routeConfig}
					/>
				</div>
			</React.Fragment>
		);

		const passwordPageFragment = showPasswordProtection && (
			<PasswordScreen password={passwordProtection.password} />
		);

		return (
			<React.Fragment>
				<Helmet defaultTitle="Genexa" />
				<Helmet>
					<html
						lang="en"
						className={classNames({
							'of--hidden': isScrollDisabled,
						})}
					/>
					<meta
						name="viewport"
						content="width=device-width, initial-scale=1.0"
					/>
				</Helmet>
				<Analytics
					googleAnalyticsPropertyId={
						process.env.GATSBY_GOOGLE_ANALYTICS_PROPERTY
					}
					gtmPropertyId={process.env.GATSBY_GTM_PROPERTY}
				/>

				{skip}
				{siteFragment}
				{accountFragment}
				{passwordPageFragment}
			</React.Fragment>
		);
	}
}

const mapStateToProps = ({
	hasEnteredSitePassword,
	hasAcceptedCookies,
	customer,
}) => ({
	hasEnteredSitePassword,
	hasAcceptedCookies,
	customer,
});

export default connectToStore(mapStateToProps)(Layout);
